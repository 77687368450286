import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { DownloadExcel } from "react-excel-export";
import { useNavigate } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import * as XLSX from "xlsx";

import { Row, Col, Card, Table } from "react-bootstrap";
import { allUser, UserBlock } from "../../../services/api_function";
import { Link } from "react-router-dom";
import { COLUMNS } from "../../components/table/FilteringTable/Columns";
import MOCK_DATA from "../../components/table/FilteringTable/MOCK_DATA_2.json";
import { NotificationManager } from "react-notifications";

export const StakingUser = () => {
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInputUnblock, setUserInputUnblock] = useState("");
  const userDetails = localStorage.getItem("userDetails");
  const parsedDetails = JSON.parse(userDetails);
  const token = parsedDetails.token;


  const pageSize = 20;

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = localStorage.getItem("userDetails");
        const parsedDetails = JSON.parse(userDetails);
        const token = parsedDetails.token;
        const result = await allUser(
          currentPage,
          { searchQuery: search },
          token
        );
        setApiData(result.data);
        setFilteredData(result.data);
        const total = result.totalUsers;
        const pages = Math.ceil(total / pageSize);
        setTotalPages(pages > 0 ? pages : 1);
        if (result.status == 404) {
          navigate("/login");
          localStorage.removeItem("userDetails");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, search]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };


  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleBlockUnblock = async (userId, action) => {
    try {
      const userDetails = localStorage.getItem("userDetails");
      const parsedDetails = JSON.parse(userDetails);
      const token = parsedDetails.token;
  
      const response = await fetch(
        `https://yourapi.com/user/${action}`, // Replace with your actual API URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ user: userId }),
        }
      );
  
      const data = await response.json();
  
      if (response.ok) {
        alert(`User ${action === "block" ? "blocked" : "unblocked"} successfully.`);
        setApiData((prevData) =>
          prevData.map((user) =>
            user.user === userId
              ? { ...user, isBlocked: action === "block" }
              : user
          )
        );
      } else {
        alert(`Failed to ${action} user: ${data.message}`);
      }
    } catch (error) {
      console.error(`Error during ${action} action:`, error);
      alert("Something went wrong. Please try again.");
    }
  };
  
  const handleBlock = (user) => {
    if (user.trim() !== "") {
      // alert(user)
      UserBlock(user, "1", token)
        .then((response) => {
          if (response.status == 200) {
            NotificationManager.success(response.message);
            setUserInputUnblock("");
          } else {
            NotificationManager.error(response.message);
          }
        })
        .catch((error) => {
          NotificationManager.error(error.message);
        });
    } else {
      setErrorMessage("User input for blocking is empty!");
    }
  };
  const handleUnblock = (user) => {
    // alert(user)
    if (user.trim() !== "") {
      UserBlock(user, "0", token)
        .then((response) => {
          if (response.status == 200) {
            NotificationManager.success(response.message);
            setUserInputUnblock("");
          } else {
            NotificationManager.error(response.message);
          }
        })
        .catch((error) => {
          NotificationManager.error(error.message);
        });
    } else {
      setErrorMessage("User input for unblocking is empty!");
    }
  };
   
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>

        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                All Users
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{
                background: "black",
                border: "1px solid white",
                borderRadius: "3px",
              }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "0.5px solid white",
                }}
              >
                {/* <button onClick={() => exportToExcel(data, 'exported-data')}>Export to Excel</button> */}
                <thead>
                  <tr>
                    <th>
                      <strong>NO.</strong>
                    </th>
                  
                    <th>
                      <strong> UserID</strong>
                    </th>
                    <th>
                      <strong>User wallet</strong>
                    </th>

                    <th>
                      <strong>Referrer Id</strong>
                    </th>
                  
                    <th>
                      <strong>Stake ($)</strong>
                    </th>
                    <th>
                      <strong>Direct Business ($)</strong>
                    </th>
               
                    <th>
                      <strong>Team Business ($)</strong>
                    </th>
                
                   <th>
                    <strong>Direct Members</strong>
                   </th>
                    <th>
                      <strong>Date&Time</strong>
                    </th>
                    <th>
                      
                    </th>
                  </tr>
                </thead>
                <tbody>
  {filteredData?.map((user, index) => (
    <tr key={user.user}>
      <td>{(currentPage - 1) * pageSize + index + 1}</td>
      <td>{user.userId}</td>
      <td>
        <span className="smaller-font">
          {user?.user?.slice(0, 4) + "..." + user?.user.slice(-12)}
        </span>
      </td>
      <td>{user.referrerId}</td>
      <td>{(user?.stake_amount ?? 0).toFixed(2)}</td>
      <td className="text-center">
        {Number(user?.directbusiness ?? 0).toFixed(2)}
      </td>
      <td className="text-center">
        {Number(user?.staketeambusiness ?? 0).toFixed(2)}
      </td>
      <td className="text-center">
        {Number(user?.directStakeCount ?? 0)}
      </td>
      <td>{formatTimestamp(user.createdAt)}</td>
      <td>
        <a
          href={`https://splosh.app/login?wallet=${user.user}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary"
          style={{
            background:
              "linear-gradient(90deg, rgb(50 30 249) 15.9%, rgb(123 17 135) 98.32%)",
            color: "#fff",
          }}
        >
          Dashboard
        </a>
      </td>
      <td>
        {!user?.income_status ? (
          <button
            className="btn btn-success"
            onClick={() => handleBlock(user.user)}
          >
            Block
          </button>
        ) : (
          <button
            className="btn btn-danger"
            onClick={() => handleUnblock(user.user)}
          >
            Unblock
          </button>
        )}
      </td>
    </tr>
  ))}
</tbody>

              </Table>

              <div className="d-flex justify-content-between">
                <span>
                  {/* Page{" "} */}
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="bg-black text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StakingUser;
